import { Injectable } from '@angular/core';
import { PotentialCustomer } from '@wellro/models';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ServerResponse } from '@wellro/auxilary-models';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  createCustomer(
    potentialCustomerId: string,
    name: string,
    email: string,
    mobileNumber: string,
    gender: string,
    dateOfBirth: string,
    nicNo: string,
    premisesNumber: string,
    street1: string,
    street2: string,
    city: string,
    district: string,
    allergies?: string
  ): Observable<string> {
    const data = {
      potentialCustomerId,
      name,
      email,
      mobileNumber,
      gender,
      dateOfBirth: new Date(dateOfBirth).getTime(),
      nicNo,
      premisesNumber,
      street1,
      street2,
      city,
      district,
      allergies,
    };
    return this.http
      .post<ServerResponse<string>>('/api/customers/profile/complete', data)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }

  getPotentialCustomerById(
    potentialCustomerId: string
  ): Observable<PotentialCustomer> {
    return this.http
      .get<ServerResponse<PotentialCustomer>>(
        `/api/potential-customers/${potentialCustomerId}`
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }
}
