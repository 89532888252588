<div class="page-not-found">
  <div class="content-container">
    <div class="icon-container">
      <div class="icon-container-inner">
        <div class="icon">:(</div>
      </div>
    </div>
    <div class="description-container">
      <div class="heading">404 - PAGE NOT FOUND</div>
      <div class="text">
        The page you are looking for might have been removed had its name
        changed or is temporarily unavailable.
      </div>
      <div class="button-container">
        <a routerLink="/">
          <button mat-raised-button color="primary">Home Page</button>
        </a>
      </div>
    </div>
  </div>
</div>
