import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentRequest } from '@wellro/models';

import {
  ServerResponse,
  CustomerPaymentRequest,
} from '@wellro/auxilary-models';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private http: HttpClient) {}
  getPaymentDetailsByOrderId(
    context: string,
    orderId: string
  ): Observable<CustomerPaymentRequest> {
    let params = {};
    if (context == 'consultation') {
      params = {
        context,
        consultationId: orderId,
      };
    }
    if (context == 'custom-payment') {
      params = {
        context,
        paymentRequestId: orderId,
      };
    }

    return this.http
      .get<ServerResponse<CustomerPaymentRequest>>(
        `/api/payments/payment-request/`,
        {
          params,
        }
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }
  getPaymentRequest(paymentRequestId: string): Observable<PaymentRequest> {
    return this.http
      .get<ServerResponse<PaymentRequest>>(
        `/api/payments/custom-payment/${paymentRequestId}`
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }
}
