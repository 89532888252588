import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConsultationInvoice,
  CustomerPaymentRequest,
  ServerResponse,
} from '@wellro/auxilary-models';
import {
  Consultation,
  Customer,
  CustomerIdentity,
  DoctorMessage,
  ModeratedContentBlock,
  PatientMessage,
  QuestionWithAnswer,
} from '@wellro/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsultationService {
  constructor(private http: HttpClient) {}

  getConsultationWithModeratedContentBlock(
    consultationId: string,
    context: string
  ): Observable<{
    consultation: Consultation;
    moderatedContentBlocks: ModeratedContentBlock[];
  }> {
    return this.http
      .get<
        ServerResponse<{
          consultation: Consultation;
          moderatedContentBlocks: ModeratedContentBlock[];
        }>
      >(`/api/consultations/${consultationId}`, { params: { context } })
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }

  confirmConsultation(
    consultationId: string,
    hasDirectPayment: boolean,
    questionnaireAnswers: { [questionId: string]: QuestionWithAnswer }
  ): Observable<string> {
    return this.http
      .post<ServerResponse<string>>(
        `/api/consultations/${consultationId}/confirm`,
        { hasDirectPayment, questionnaireAnswers }
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }

  rejectConsultation(consultationId: string): Observable<string> {
    return this.http
      .post<ServerResponse<string>>(
        `/api/consultations/${consultationId}/reject`,
        null
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }

  confirmAdditionalData(consultationId: string): Observable<string> {
    return this.http
      .post<ServerResponse<string>>(
        `/api/consultations/${consultationId}/additional-data/confirm`,
        null
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }

  rejectAdditionalData(consultationId: string): Observable<string> {
    return this.http
      .post<ServerResponse<string>>(
        `/api/consultations/${consultationId}/additional-data/reject`,
        null
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }

  getConsultationMessage(
    consultationId: string
  ): Observable<{
    consultation: Consultation;
    messages: (PatientMessage | DoctorMessage)[];
  }> {
    return this.http
      .get<
        ServerResponse<{
          consultation: Consultation;
          messages: (PatientMessage | DoctorMessage)[];
        }>
      >(`/api/consultations/${consultationId}/message-thread`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }
  getConsultationInvoice(
    consultationId: string
  ): Observable<ConsultationInvoice> {
    return this.http
      .get<ServerResponse<ConsultationInvoice>>(
        `/api/consultations/${consultationId}/invoice`
      )
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }
  getCustomerByConsultationId(
    consultationId: string
  ): Observable<{ customer: Customer; customerIdentity: CustomerIdentity }> {
    return this.http
      .get<
        ServerResponse<{
          customer: Customer;
          customerIdentity: CustomerIdentity;
        }>
      >(`/api/consultations/${consultationId}/customerIdentity`)
      .pipe(
        map((response) => {
          if (response.success) {
            return response.data;
          } else {
            throw new Error(response.message);
          }
        })
      );
  }
}
