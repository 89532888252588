import { LogLevel } from '@wellro/utils';

export const environment = {
  production: true,
  whatsAppNumber: '+94705302302',
  firebase: {
    apiKey: 'AIzaSyAgWd7sdHMnm4sSBItDLKw1HvlD8aWkOe4',
    authDomain: 'wellro-wellness-prod.firebaseapp.com',
    projectId: 'wellro-wellness-prod',
    storageBucket: 'wellro-wellness-prod.appspot.com',
    messagingSenderId: '691919511102',
    appId: '1:691919511102:web:64728c4a87b275730c3b6f',
    measurementId: 'G-HQZR38FJGL',
  },
  tenantId: 'customer-ixx2d',
  logLevel: LogLevel.WARNS,
  hostUrl: 'https://wellro.life',
};
