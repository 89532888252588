import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { UtilsModule } from '@wellro/utils';
import { AuthDataAccessModule } from '@wellro/auth/data-access';
import { ConfigurationsModule } from '@wellro/configurations';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, TENANT_ID } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { environment } from '../../environments/environment';
import { UserService } from '../core/user.service';
import { ConsultationService } from './consultation.service';
import { PaymentService } from './payment.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    ConfigurationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    UtilsModule.forRoot({ baseUrl: '/api', logLevel: environment.logLevel }),
    AuthDataAccessModule.forRoot({ authUrl: '/auth' }),
  ],
  providers: [
    UserService,
    ConsultationService,
    PaymentService,
    { provide: TENANT_ID, useValue: environment.tenantId },
  ],
})
export class CoreModule {}
